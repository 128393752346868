.service-tab-section{
    margin-top: 100px;
    padding: 0 86px;
}
@media only screen and (max-width: 767px) {
    .service-tab-section{
        margin-top: 50px;
        padding: 0 15px;
    }
    .custom-tab .nav{
        flex-wrap: inherit;
        display: inline;
    }
}