.custom-tab .nav-tabs .nav-link{
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    border: 0;
}
.custom-tab .nav-tabs .nav-link.active{
    background-color: #FFB900;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    border: 0;border-radius: 0;
}
.nav-tabs{
    border: 0;
}
.tab-article{
    border-left: 7px solid #FFB900;
    padding-left: 62px;
    font-size: 24px;
    font-weight: 700;
    color: #051951;
    margin: 65px 20px;
}
.tab-content{
    padding: 100px 6%;
}
.tab-image-banner{
text-align: center;
}
.tab-image-banner img {
    max-width: 100%;
}
@media only screen and (max-width: 767px) {
    .tab-content{
        padding: 15px;
    } 
}