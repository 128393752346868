.product-card-component {
  width: 30%;
  /* height: 407px; */
  position: relative;
  flex: 1 1 auto;
  margin-top: 13px;
  display: flex;
    justify-content: center;
}

.product-title {
  height: 63px;
  bottom: 0;
  color: #ffffff;
  padding: 16px 16px;
  text-transform: capitalize;
  position: absolute;
}
.product-hover-section{
  background: #FFB900;
  width: min-content;
}
.product-content{
  padding: 10px;
  color: #1D1D1F;
}
.product-title-hover{
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}
.product-title-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.product-button{
  width: 188px;
  padding: 16px 32px;
  height: 59px;
  color: #051951;
  font-weight: bold;
  border: 1px solid #051951;
  background-color: #FFB900;
}
.product-image{
  /* width: 100%; */
}
@media only screen and (max-width: 767px) {
  .product-hover-section{
    width: 100%;
  }
}
