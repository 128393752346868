.custom-button .primary,
.custom-button .secondary {
  width: 188px;
  padding: 16px 32px;
  height: 59px;
  color: #fefefe;
  text-transform: capitalize;
  font-weight: bold;
  border: 0;
}
.primary {
  background-color: #ffb900;
}
.secondary {
  background-color: #272db0;
}

@media only screen and (max-width: 767px) {
  .custom-button .primary,
.custom-button .secondary {
  width: 145px;
  padding: 0;
  height: 40px;
}
}
