@import "./common/fonts/font.css";

body {
  margin: 0;
  font-family: Barlow;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}
ul {
  margin: 0;
  padding: 0;
}
