
.about-us-content-section{
    background-color: #272DB0;
    padding: 180px 140px;
    color: #fff;
}
.about-us-content-section-col1, .about-us-content-section-col2{
    width: 50%;
    display: inline-block;
}
.about-us-content-section-col1{
    padding-right: 13%;
}
.about-us-content-section-col1{
    padding-left: 2%;
}
.about-icon-section{
    display: flex;
    border-bottom: 1px solid #fff;
    padding: 25px 0;
}
.about-icon-section:last-child{
    border: 0;
    padding-bottom: 0;
}
.about-icon-section:first-child{
    padding-top: 0;
}
.about-icon-des{
    margin-left: 30px;
}
.about-us-content-heading{
    color: #FFB900;
font-size: 26px;
font-weight: 700;
margin-bottom: 32px;
}
.about-us-content-sub-heading{
    font-weight: 700;
    font-size: 16px;
}
.about-us-content-text{
    font-weight: 400;
    font-size: 16px;
    color: #F4F4F4;
    margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
    .about-us-content-section{
        padding: 15px;
    }
    .about-us-content-section-col1, .about-us-content-section-col2{
        width: 100%;
        padding: 15px;
    }
    .about-us-content-section-col1{
        padding-right: 15px;
    }
    .about-us-content-heading{
        text-align: center;
    }
    .custom-button{
        text-align: center;
    }
}