.career-section{
    height: 600px;
    background-color: #fff;
    text-align: center;
    font-size: 30px;
    padding-top: 30px;
}
@media only screen and (max-width: 767px) {

    .service-content ul {
        display: inline-block;
        text-align: left;
    }
    .ul-container{
        text-align: center;
    }
}