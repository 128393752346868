

.tech-list-component {
  padding: 139px;
  background: #272db0;
  display: flex;
  flex-wrap: wrap;
}
.expert-products {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.expert-prduct-image {
  width: 24%;
  padding: 5px;
  max-width: 114px;
  max-height: 114px;
  margin-right: 7%;
}
.expert-prduct-image img {
  width: 100%;
  height: auto;
}

.data-component {
  background: #ffb900;
  padding: 74px 30px;
  display: flex;
  max-width: 1185px;
  margin: 0 auto;
}
.data-number {
  color: #ffffff;
  border-right: 2px solid #ffffff;
  width: 25%;
  margin-left: 20px;
  text-align: center;
}
.count {
  font-size: 67px;
  margin: 0;
  font-weight: 700;
}
.data-text {
  font-size: 24px;
  margin: 0;
  font-weight: 700;
}
.different-component {
  position: relative;
}
.different-component img {
  width: 100%;
}
.different-component-content {
  position: absolute;
  bottom: 60px;
}
.different-component-content .component-heading{
  margin-bottom: 25px;
}
.diff-btn-section{
  margin-top: 35px;
}
.different-comp-section {
  width: 72%;
  margin: 25px auto 0;
}
.industry-component-card-section{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.industry-component{
  background: #F5F6F8;
  padding: 90px 216px;
  display: flow-root;
}
.industry-component-text{
  color: #1D1D1F;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
}
.industry-component-card{
  width: 270px;
  background: #FFFFFF;
  box-shadow: 0px 0px 37px -2px rgba(17, 24, 39, 0.05);
  border-radius: 4px;
  margin-left: 10%;
  margin-top: 45px;
  display: inline-block;
  text-align: center;
  padding: 29px 0;
}
.industry-component-card img{
  width: 77px;
}
.industry-component-card-divider{
  width: 60px;
  height: 2px;
  background: #E0E3EB;
  margin: 20px auto;
}
.industry-component-card-text{
  color: #272DB0;
  font-size: 20px;
  font-weight: 700;
}
.industry-component-card:nth-child(even){
  background: #272DB0;
}
.industry-component-card:nth-child(even) .industry-component-card-text{
  color: #ffffff;

}
.industry-component-card:nth-child(4n), .industry-component-card:nth-child(1) {
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .tech-list-component{
    padding: 15px;
  }
  .product-card-component, .product-card-component img{
    width: 100%;
    overflow: hidden;
  }
  .product-card-component img{
    max-width: 100%;
  }
  .data-component{
    display: block;
  }
  .data-number{
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border: 0;
  }
  .data-number:nth-child(even){
    border: 0;
  }
  .data-number:nth-child(odd){
    margin-right: 20px;
  }
  .industry-component{
    padding: 20px 15px;
  }
  .industry-component-card-section{
    text-align: center;
  }
  .industry-component-card{
    margin: 20px 0 ;
  }
  .different-comp-section{
    width: 100%;
  }
  .different-component img{
    width: 100%;
  }
  .different-component-content {
    position: absolute;
    bottom: 5px;
  }
  .different-component-content .component-heading{
    font-size: 20px;
  }
  .different-component-content .heading-dash{
    height: 5px
  }
  .different-component-content .content-text{
    font-size: 14px;
    line-height: 23.2px;
  }
  .different-component{
    display: none;
  }
}