.footer-component{
    color: #FFF;
    margin-top: 50px;
}
.footer-component-links{
    background-color: #272DB0;
    padding: 112px 146px;
}
.footer-copy-rights{
    background-color: #FFB900;
    color: #fff;
    text-align: center;
    padding: 36px 0;
}
.footer-comp-info-text{
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    margin-top: 20px;
}
.footer-comp-info-text span{
    display: block;
}
.footer-header{
    font-weight: bold;
    font-size: 22px;
}
.quick-link, .quick-link-img{
    display: flex;
}
.quick-link{
    margin: 0 0 12px;
    padding: 0;
    font-weight: 700
}
.quick-link-img img{
    max-width: 100%;
}
.quick-link-text{
    margin-left: 10px;
}
.footer-section{
    display: inline-block;
    vertical-align: top;
}
.footer-comp-info{
    width: 30%;
    visibility: hidden;
}
.footer-links{
    width: 20%;
    margin-left: 60px;
}
.footer-reach-us{
    width: 25%
}
@media only screen and (max-width: 767px) {
    .footer-component-links{
        padding: 15px 15px 15px 30px;
    }
    .footer-comp-info{
        width: 100%;
    }
    .footer-links{
        width: 50%;
        margin-left: 0;
    }
    .footer-reach-us{
        width: 50%;
    }
    .footer-copy-rights{
        padding: 5px 0;
    }
}