.carousel-caption {
  text-align: left;
  width: 65%;
}
.carousel-caption{
  position: absolute;
    left: 15%;
    color: #fff;
    margin: auto 0;
    bottom: 0;
    right: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.bold-text{
  font-weight: 700;
}
.carousel-caption h3 {
  font-size: 50px;
  padding-right: 4%;
  line-height: 60px;
}

.carousel-caption p {
  font-size: 26px;
  font-weight: 500;
  line-height: 41.6px;
}
.carousel-item img {
  max-height: 500px
}
.carousel-indicators{
  justify-content: flex-start;
}
.carousel-indicators [data-bs-target]{
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .custom-carousel{
    height: 227px;
    overflow: hidden;
    width: 100%;
  }
  .carousel, .carousel-inner, .carousel-item, .carousel-item img{
    height: 100%;
  }
  .carousel-caption {
    text-align: left;
    width: auto;
    top: 0;
    transform: none;
    left: 0;
  }
  .carousel-caption h3 {
    font-size: 25px;
    font-weight: 700;
    padding-right: 4%;
    line-height: 30px;
    margin-left: 50px;
  }
  
  .carousel-caption p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 50px;

  }
  .carousel-indicators{
    justify-content: center
  }
}
