.component-heading {
  font-size: 50px;
  font-weight: 500;
  color: #051951;
}

.heading-dash {
  width: 80px;
  height: 12px;
  background: #ffb900;
  margin: 15px auto;
}
.content-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 27.2px;
  color: #666666;
  text-align: center;
}
.center {
  text-align: center;
}
.mt30 {
  margin-top: 30px;
}
.border0 {
  border: 0 !important;
}
.white {
  color: #ffffff !important;
}
.pb0{
  padding-bottom: 0 !important;
}
.font-bold{
  font-weight: bold;
}
.text-left{
  text-align: left !important;
}
.ml0{
  margin-left: 0 !important;
}
.service-component {
  padding: 100px;
  margin: 0 auto;
}
.service-content {
  margin-top: 30px;
}
.page-banner{
  position: relative
}
.page-banner img {
  width: 100%;
}
.page-caption{
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 130px;
}
.page-caption h2{
  font-weight: 700px;
  font-size: 50px;
}
.page-caption p{
  font-size: 26px;
  margin-bottom: 0;
}
.col-6{
  width: 48%
}
.bold-text{
  font-weight: 700;
}
input{
  height: 70px;
  background: #FEFEFE;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  font-size: 16px;
  color: #666666;
  padding-left: 20px;
}
textarea{
  background: #FEFEFE;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  font-size: 16px;
  color: #666666;
  padding-left: 20px;
  padding-top: 20px;
  height: 174px;
}
.ml30{
  margin-left: 30px;
}
.fleft{
  float: left;
}
.fright{
  float: right;
}
.show-mobile{
  display: none;
}

@media only screen and (max-width: 767px) {
  .service-component {
    padding: 50px 0;
  }
  .component-heading{
    font-size: 32px;
  }
  .service-content{
    padding: 0 15px;
  }
  .hide-mobile{
    display: none;
  }
  .show-mobile{
    display: block;
  }
  .page-banner img{
width: 100%;
  }
  .page-caption{
    margin: 10px;
    width: 100%;
    top: 0;
    transform: none;
  }
  .page-caption h2{
    font-size: 25px;
  }
  .page-caption p{
    font-size: 16px;
  }
  .content-text{
    text-align: left;
  }
  .menu-expand{
    height: 100vh;
    overflow: hidden;
  }
}
