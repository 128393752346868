.conatct-email-section{
    margin: 120px 0;
    padding: 175px 150px;
    text-align: center;
    color: #fff;
    background-color: #272DB0;
}
.email-heading{
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 0;
}
.email-text{
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 30px;
    margin-bottom: 60px;
    padding: 0 20%;
}
.email-name, .email-field{
    width: 48%;
}
.email-name input, .email-field input{
    width: 100%;
}
.email-subject{
    width: 100%;
    margin-top: 30px;
}
.email-message{
    width: 100%;
    margin-top: 30px;
}
.email-submit-button{
    width: 189px;
height: 59px;
background: #FFB900;
padding: 16px 32px;
text-align: center;
border: 0;
color: #fff;
float: right;
    margin-top: 30px
}
.error-msg{
    color: red;
    text-align: left;
    margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
    .conatct-email-section{
        margin-top: 0;
        padding: 15px;
    }
    .email-heading{
        font-size: 25px;
        text-align: left;
    }
    .email-text{
        font-size: 16px;
        line-height: 23px;
        padding: 0;
        text-align: left;

    }
    .conatct-email-section .heading-dash{
        display: none;
    }
    .email-name, .email-field{
        width: 100%;
    }
    .email-field{
        margin-top: 30px;
    }
    .email-submit-button{
        float: none;
    }
}